import React, {useState, useEffect} from "react";
import { Navigate, useLocation, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
//Utils
import { useAuth, useData, useWebSocket } from "../../../Utils/UseContext.jsx";
import { Loading, DateNow, DateToString, AddTokenToHeaders} from "../../../Utils/Utils.jsx";
//API
import { ConfirmAccessRight } from "../../../Utils/ApiRequests.jsx";
//COmponents
import { Modal, ScrollView, ExpiryDate, CustomPopUp} from "../../../components/Components.jsx";
import { WorkoutMedia } from "../WorkoutMedia.jsx";
//icon
import { MdOutlineReadMore } from "react-icons/md";
//Style
import { ButtonContainer, Container100, PublishDetailsContainer, DescriptionContainer } from "../../../components/style/Container.styled.jsx";
import { Column, Row } from "../../../components/style/Row.styled.jsx";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled.jsx";
import { WorkoutRow, WorkoutContent, WorkoutContainer, ExerciseContainer} from '../StyledComponents.jsx'

const PublicExerciseRow = ({nameofcreator, partnames, title, price, expiredIn, media, onClick}) => {
     return(
       <WorkoutRow columns={'0.5fr 0.9fr 1.1fr 0.40fr'} onClick={onClick}>
       <WorkoutMedia src={media} type="image"/>
       <WorkoutContent align={'flex-start'} justify={'space-between'} font={10}>
         <h4>{title}</h4>
         <p>{partnames?.length} part exercise</p>
         <p>Created by {nameofcreator}</p>
       </WorkoutContent>
       <WorkoutContent align={'flex-start'} justify={'center'} >
         <p>{'Expires in'} {expiredIn} {'days'}</p>
         <p>{'Price'}: {price}{'€'}</p>
       </WorkoutContent>
       <WorkoutContent align={'flex-start'} justify={'center'} font={10}>
        <MdOutlineReadMore size={30}/>
        <p>More...</p>
       </WorkoutContent>
     </WorkoutRow>
     )
   }
  
export const PublicExerciseSection = ({data, theme}) =>{
      const navigate = useNavigate();
      const {state} = useAuth();
      const location = useLocation();
      const {value} = location.state || {};
      const [isOpen, setIsOpen] = useState(false);
      const [previewData, setPreviewData] = useState(null);

      const publicExercise = Object.values(data).map(item => {
        const workout_name = item?.workout_name;
        const username = item?.username;
        const workout_id = item?.workout_id;
        const description = item?.description;
        const expired_time = item?.expired_time;
        const price = item?.price;
        const media = item?.media_url;
        const display_parts = item?.display_parts;
        const part_names = item?.part_names;
  
        return (
          <PublicExerciseRow key={Math.random()} 
            onClick={() => navigate(`/workout/public/${workout_id}`)} 
            id = {workout_id} nameofcreator = {username} title = {workout_name} partnames={part_names} price = {price} expiredIn = {expired_time} description = {description} media={media} display_parts={display_parts}/>
        )
      })
  
      return (
        <ExerciseContainer justify={'start'} >
          {publicExercise}
        </ExerciseContainer>
        );
   }