import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";

//components
import { ScrollView, MapPartName } from "../../../components/Components.jsx";

//Style
import { WorkoutContent, WorkoutRow} from "../StyledComponents.jsx";
//utils
import { Loading, isAccessRightValid} from "../../../Utils/Utils.jsx";
import { GetRole } from "../../../Utils/Role.jsx";


export const PersonalExerciseRow = ({data, selected}) => {

  let mapMyWorkout;
  {data && (
  mapMyWorkout = Object.keys(data).map(id => {
    let accessRight = data[id].accessRight;
    let name = Object.keys(data[id])[0];
    let part = MapPartName(data[id][name]);
    let expires = new Date(data[id].expiration_date);

    return (
      <WorkoutRow key={Math.random()} onClick={() => selected(id, name, accessRight)} columns={'1fr 0.3fr'}>
        <WorkoutContent key={Math.random()} align={'flex-start'} font={9}>
          <p key={Math.random()}>{isAccessRightValid(accessRight, 'owner') ? 'My' : 'public'}</p>
          <h4 key={Math.random()}>{name}</h4>
          {accessRight != 'owner' && (<p style={{fontSize: 10, opacity:'80%'}}>Expires: {expires.getDate()}.{expires.getMonth()}.{expires.getFullYear()}</p>)}
        </WorkoutContent>
        <WorkoutContent key={Math.random()} align={'flex-end'}>
          <ScrollView items={part} renderItem={(item) => <span>{item}</span>}/>
        </WorkoutContent>
      </WorkoutRow>
    )
  }
  )
)}

return (
    // <ExerciseContainer>
      <ScrollView items = {mapMyWorkout} renderItem={(item) => <span>{item}</span> } />
    // </ExerciseContainer>
)
}

const PersonalExercises = ({theme, personalData, setPersonalData}) => {
    const navigate = useNavigate();
    const [role, setRole] = useState(null)

    useEffect(() => {
      const userRole = async () => {
        const role = await GetRole();
        setRole(role)
      }
      userRole();
    },[])

    if(!personalData){
      const loading = personalData ? false : true;
      return (
        <div style={{marginTop: 50, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <ClipLoader
          color={theme.colors.button}
          loading={loading}
          // cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <p>Loading...</p>
        </div>
      )
    } 
    // <Loading data={personalData} timeout={1000} />

      const selected = async (id, name, accessRight) => {  
         if(personalData[id][name] != undefined || personalData[id][name] != null){
          const data = {id: id, name: name, list:personalData[id][name]}
          navTo(data, accessRight, role)
         }else{
           console.error("SOMETHING WENT WRONG!")
         }
    }

    const navTo = (values, accessRight, role) => {
      const id = values.id;

      navigate(`/workout/my/part/${id}`, { state: {values, accessRight, role} });
    }

    return (
        <>
          <PersonalExerciseRow data = {personalData} setPersonalData = {setPersonalData} selected = {selected} role = {role}/>
        </>
    )
}

export default PersonalExercises;