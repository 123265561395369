import styled, {keyframes} from 'styled-components';

export const HeaderWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    aling-items: center;
`

export const PageWrapper = styled.div`
    flex: 1;
    height:100vh;
`
export const Content20Round = styled.div`
    min-width: 90vw;
    padding: 10px;
    border: 1px solid ${({theme}) => theme.colors.border};
    border-radius: 20px;
    margin: 20px;
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify || 'space-evenly'};
    flex-direction: ${(props) => props.direction || 'row'};
    padding: ${({padding}) => padding || '25px 0 50px 0'};
    width: 100vw;
    // height: 95vh;
    background: ${({background}) => background && (background)}
    overflow: auto;

`;

export const ColumnContainer = styled.div`
    display:flex;
    flex-direction: column;
`
export const PublishContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify || 'space-evenly'};
    flex-direction: ${(props) => props.direction || 'column'};
    background: ${({theme}) => theme.colors.body};

    @media screen and (min-width: 600px) { // desktop
        width: 95vw;
        max-width: 1300px;


        h3{
            font-size: 30px;
        }
        
        p{
            font-size: 20px;
        }
        
        input{
            font-size: 20px;
        }

    }

    @media screen and (max-width: 600px) { // mobile
        width: 100vw;
        height: 100vh;
    }


`

export const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 100vw;
    padding-bottom: 3em;
    //padding-top: 1em;
    overflow:auto;
    // height: 100%;

    @media screen and (min-width: 500px) {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items: flex-start;
        padding: 2em;
    }
`

export const MainContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.justify || 'center'};
    align-items: ${(props) => props.align && (props.align)};
    //margin: ${({marginTop}) => marginTop || '10em'} 0 1em 0;
    background: ${({bg}) => bg || 'transparent'};
    border-radius: 20px;
    width: 100vw;

    @media screen and (min-width: 600px) { // desktop
        & > h1 {
            font-size:40px;
        }
        
        background: ${({bg}) => bg || 'transparent'};
        width: ${({width}) => width || ""};
        // height: ${({height}) => height || '55vh'};
        display: flex;
        justify-content: ${({justify}) => justify || 'center'};

    }
`

 export const TextContentContainer = styled.div`
     text-align: justify;
     color: ${({theme}) => theme.colors.text};
     // padding: 0.5em;
     // height: 100%;
     // overflow: auto;

     @media screen and (min-width: 600px) {
         font-family: Arial, Helvetica, sans-serif;
         font-size: ${(props) => props.fontSize || '16px'};
         font-weight: 500;
     }
 `

export const TimerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 20px;
`

export const DescriptionContainer = styled.div`
    border: 0.3em double ${({theme}) => theme.colors.border};
    border-radius: 0.5em;
    max-width: 500px;
    min-height: 100px;
    max-height: 80vh;
    text-align: center;
    padding: 20px;
    color: ${({theme}) => theme.colors.text};
    background: ${({theme}) => theme.colors.body};
    overflow: auto;

     @media screen and (min-width: 481px) and (min-height: 500px) {


    }
`

export const ButtonContainer = styled.div`
    display: flex;
    // justify-content: ${({justify}) => justify || 'space-between'};
    justify-content:space-between;
    width:100%;
    padding: ${({padding}) => padding || '5px'};

     @media screen and (min-width: 481px) and (min-height: 500px) {
        // margin: 0 auto;
        // width:100%;
        max-width: 50em;
        justify-content: ${({justify}) => justify || 'space-between'};
     }

`

export const RightBottomFloatingContainer = styled.div`
    position:fixed; 
    right:15px;
    bottom: ${({bottom}) => bottom || '60px'};
`
export const BottomFloatingContainer = styled.div`
    position:absolute;
    bottom: 42px;
    width: 100%;
`
export const RightTopAbsoluteFloatingDiv = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
`

export const HomeContentContainer = styled.div`
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    border: ${({theme}) => theme.colors.border} double 1px;
    border-radius: 1em;
    width: 20em;
    height: fit-content;
    min-height: 6em;
    padding: 1em;
    background: ${({theme}) => theme.colors.body};
`

export const AuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;
    height: 80vh;

@media screen and (min-width: 500px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative; /* Mahdollistaa z-indexin käytön */

    & > div {
        width: 50%;
    }

}
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 10em;
    margin-top: 2px;
    width: 100vw;
    padding: 0 10px 0 10px;

 @media screen and (min-width: 500px) {
     display: flex;
     justify-content: space-evenly;
     flex-direction: column;
     width: 20em;
    //  height: 10em;
     margin: 0 auto;
 }
`

export const AuthContent = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;

     p {
        margin: 10px 0 10px 0;
        background: ${({theme}) => theme.colors.textBg};
    }
`

export const PrivacyPolicyContainer = styled.div`
    overflowX: auto;
    // height: 100vh;
    max-width: 50em;
    padding: 2em 2em 6em 2em;

    & > div > h3{
        margin-top: 15px;
        margin-bottom: 10px;
    }

    & > div{
        background: ${({theme}) => theme.colors.textBg}
    }
`

export const ProfileContainer = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // height: 89vh;
    padding-top: 5em;
    padding-bottom: 5em;
    overflow: auto;

`

export const ProfileContent = styled.div`
    background: ${({theme}) => theme.colors.body};
    width: 20em;
    height: 20em;
    margin: 20px auto 20px auto;
    padding: 20px;
    border: 2px solid ${({theme}) => theme.colors.border};
    border-radius: 10%;

`

export const RoundImgContainer = styled.div`
    background: ${({theme}) => theme.colors.body};
    //background: ${({ theme, src }) => src ? `url(${src})` : theme.colors.body};
     background-image: ${({ src}) => src ? `url(${src})` : 'none'};
    background-size: contain; /* Säädä kuvan kokoa */
    background-repeat: no-repeat; /* Estää kuvaa toistumasta */
    background-position: left center;
    position: relative;
    top: ${({top}) => top || '-4em'};
    left: ${({left}) => left || '-2em'};
    width: ${({size}) => size || '7em'};
    height: ${({size}) => size || '7em'};
    border: 2px solid ${({theme}) => theme.colors.border};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 1;

  svg {
    font-size: ${({ size }) => size ? `calc(${size} / 2)` : '3.5em'};
    color: ${({ theme }) => theme.colors.text};
  }
`
export const SquareImgontainer = styled.div`
background-image: url(${(props) => props.src});
background-size: contain; /* Säädä kuvan kokoa */
background-repeat: no-repeat; /* Estää kuvaa toistumasta */
background-position: left center;
width: 4em;
height: 4em;
border: 0.5px solid ${({theme}) => theme.colors.border};
border-radius: 10px;

`

export const StyledUserDetails = styled.div`
    // position: relative;
    // top: -7.5em;
    // left: 6em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;
    // height: fit-content !important;
    // width: 12em;
    height: 90%;
    margin-top: -90px;
    // overflow-x: auto;

    & > div {
        margin: 3px auto;
    }

    & > div > span {
        display: flex;
        align-items: flex-end; 
        white-space: nowrap;
        padding: 2px 0 2px 0;
    }

    svg {
        margin-right: 15px;
    }
`
export const OverflowTextContent = styled.div`
    overflow-x: auto;
`

export const ContactDetails = styled.div`
    // position: relative;
    // bottom: 5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: -50px;
    
    & > span {
        display: flex;
        align-items: flex-end;
        white-space: nowrap;
        padding: 5px auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    svg {
        margin-right: 10px;
        flex-shrink: 0; /* Estää ikonia kutistumasta */
    }
`

export const DropdownContainer = styled.div`
  position: relative;
`;

export const NativeSelect = styled.select`
  width: 100%;
  border: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  color: ${({theme}) => theme.colors.text};
`;

export const CalendarInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em auto;
    
    height: 8em;
    border-radius: 10px;
    padding: 2em;
    background: ${({theme}) => theme.colors.textBg};
    overflow: auto;


    @media screen and (min-width: 500px) {
        max-width: 70vw;
    }

`

export const CalendarPlanerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    width: 90vw;
    background: ${({theme}) => theme.colors.textBg};
    // align-items: center;
    padding-top: 50px;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    & > button {
        width: 20em;
        margin: auto;
        font-size: 20px
        padding:auto;
    }
`

export const DropzoneContainer = styled.div`
position: relative;
border: 2px dashed ${({theme}) => theme.colors.border};
padding: 10px;
text-align: center;
cursor: pointer;
width: 100px;
height: 100px;
display: flex;
justify-content: center;
align-items: center;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const PreviewImage = styled.img`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
max-width: 120px;
max-height: 120px;
object-fit: contain;
z-index: 1;
`;

export const PlaceholderText = styled.p`
  z-index: 2;
  position: relative;
  color: ${({theme}) => theme.colors.text};
`;

export const ScrollAuto = styled.div`
    height: ${({height}) => height || '100px'};
    max-width: ${({width}) => width || '100px'};
    overflow:auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    //background:red;
`
export const Container100 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    @media screen and (max-width: 500px) { // mobile
        height: 85vh;
        width: 100vw;
    }

    @media screen and (min-width: 500px) { // desktop
        height: 100vh;
        width: 70vw;
        max-height: 80vh;
    }

`

export const PublishDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

export const TextContainerMain = styled.div`
  width: 40vw; 
  font-size: 48px;
  line-height: 1.5;
//   padding: 100px 20px;
  text-align: center;
  color: rgba(255, 255, 255, ${({ opacity }) => opacity});
  transition: opacity 0.3s ease-out;
`;

export const FadeInContainer = styled.div`
  height: 100px; /* Aluksi näkyvä korkeus */
  overflow: hidden;
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out, max-height 1200ms ease-out;
  will-change: opacity, transform, visibility, max-height;

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`;


// Fade-in keyframes
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Container for the text with the desired effect
export const TextContainer = styled.div`
  position: relative;
  padding: 50vh 0; /* Adds padding to center the fully visible text vertically */
  text-align: center;
  font-size: 2em;
  color: #000; /* Default text color (for fully visible state) */
  transition: color 0.5s ease-out, -webkit-text-stroke 0.5s ease-out;
  animation: ${fadeIn} 1.5s ease-out forwards;

  &.outline-top,
  &.outline-bottom {
    color: transparent; /* Makes the text transparent */
    -webkit-text-stroke: 1px black; /* Adds black stroke around the transparent text */
  }
`;

export const FadeInWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  transform: translateY(20vh);
  transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`;

export const FadeInText = styled.div`
  font-size: 2em;
//   color: ${({ opacity }) => `rgba(255, 255, 255, ${opacity})`};
  color: ${({ theme, opacity }) => {
    const [r, g, b] = theme.colors.text.match(/\d+/g); // Oletus: theme.colors.text palauttaa RGB-arvot
    return `rgba(${r}, ${g}, ${b}, ${opacity})`; // Käytetään opacity-muuttujaa
  }};
  opacity: ${({ opacity }) => opacity};
  transition: color 0.5s ease-out;
  margin: 20px 0;
//   text-align: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
//   align-items: center;
//   padding: 100px 0;
//   height: 200vh;
`;
