import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './components/style/Global.jsx'
import { AuthProvider, DataProvider, TimerProvider, useAuth, WebSocketProvider } from './Utils/UseContext.jsx';
import { useDarkMode } from './components/Components.jsx';
import { language } from './Utils/Utils.jsx';
import { Colors } from './components/style/Colors.jsx';
import Logo from './components/style/images/Logo.png'
import { LogoWrapper } from './components/style/Logo.styled.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Main from './Main/Main.jsx';
import Login from './Auth/Login.jsx';
import Registeration from './Auth/Registeration.jsx';
import Profile from "./Main/Profile.jsx";
import PersonalWorkout from "./Main/Workout/Personal/PersonalWorkout.jsx";
import MyExercise from './Main/Workout/Personal/MyExercise.jsx';
import PublicWorkout from './Main/Workout/Public/PublicWorkout.jsx';
import NewWorkout from './Main/Workout/Modify_workout/NewWorkout.jsx';
import Calendar from './Main/Calendar/Calendar.jsx';
import WorkoutPlaner from './Main/Calendar/WorkoutPlaner.jsx';
import { Nav } from "./components/Nav.jsx";
import Header from './components/Header.jsx'
import EditWorkout from './Main/Workout/Modify_workout/EditWorkout.jsx';
import EmailVerification from './Auth/Verify.jsx';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.jsx';
import TermOfService from './TermsOfService/TermsOfService.jsx';
import Contact from './components/ContactForm.jsx';
import Analytics from './Main/Analytics/Analytics.jsx';
import {ForgotPassword, ResetPassword} from './Auth/ForgotPassword.jsx';
import WorkoutPartList from './Main/Workout/Personal/WorkoutPartList.jsx';
import PreviewScreen from './Main/Workout/Public/PublicPreviewScreen.jsx';
import Notifications from './Main/Notifications/Notifications.jsx';
import RateWorkout from './Main/Workout/Public/RateWorkout.jsx';
import {Checkout, Return} from './Main/Stripe/Checkout.jsx';
import StripeHosted from './Main/Stripe/StripeHosted.jsx';
// Utils
import {savePerformanceDataToDatabase, GetRole} from './Utils/Utils.jsx'




function ProtectedRoute({ children }) {
  const {state} = useAuth();

  const isAuthenticated = state.isAuthenticated// määritä, onko käyttäjä kirjautunut
  return isAuthenticated ? 
    <WebSocketProvider>
    <DataProvider>
      {children}
    </DataProvider>
    </WebSocketProvider>
     : (<Navigate to="/" replace />);
}

function DataRoute({children}) {
  return (
    <WebSocketProvider>
    <DataProvider>
      {children}
    </DataProvider>
    </WebSocketProvider>
  )
}

function LoginRoute({children}){
  const {state} = useAuth();
  const isAuthenticated = state.isAuthenticated;
  return isAuthenticated ? <Navigate to="/" replace /> : children;
}


function App() {
  const isDarkMode = useDarkMode();
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect( () => {

    const handler = (e) => {
          e.preventDefault();
          setDeferredPrompt(e);
        };
    
        window.addEventListener('beforeinstallprompt', handler);
    
        return () => {
          window.removeEventListener('beforeinstallprompt', handler);
        };

  },[])

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.info('User accepted the install prompt');
        } else {
          console.info('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };
  
  const theme = {
    colors: {
      body: isDarkMode ? Colors.darkMode.BG_COLOR : Colors.lightMode.BG_COLOR,
      textBg: isDarkMode ? Colors.darkMode.TEXT_BG_COLOR : Colors.lightMode.TEXT_BG_COLOR,
      text: isDarkMode ? Colors.darkMode.TEXT_COLOR : Colors.lightMode.TEXT_COLOR,
      btn_hover: isDarkMode ? Colors.darkMode.BUTTON_HOVER : Colors.lightMode.BUTTON_HOVER,
      button: isDarkMode ? Colors.darkMode.BUTTON_COLOR : Colors.lightMode.BUTTON_COLOR,
      border: isDarkMode ? Colors.darkMode.BORDER_COLOR : Colors.lightMode.BORDER_COLOR,
      shadow: isDarkMode ? Colors.darkMode.SHADOW_COLOR : Colors.lightMode.SHADOW_COLOR,
      popUp: isDarkMode ? Colors.darkMode.POPUP_COLOR : Colors.lightMode.POPUP_COLOR,
      disabledText: isDarkMode ? Colors.darkMode.DISABLED_TEXT : Colors.lightMode.DISABLED_TEXT,
      warning: Colors.global.WARNING
    },
    calendarColors: {
      tile: isDarkMode ? Colors.calendar.darkMode.TILE : Colors.calendar.lightMode.TILE,
      border: isDarkMode ? Colors.calendar.darkMode.BORDER_COLOR : Colors.calendar.lightMode.BORDER_COLOR,
      hover: isDarkMode ? Colors.calendar.darkMode.HOVER : Colors.calendar.lightMode.HOVER,
    }
}

  return (
    <ThemeProvider theme={theme}>
    <GlobalStyle />
    {/* <LogoWrapper src={Logo} /> */}
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={isDarkMode ? `dark` : `light`}
    />
    <AuthProvider>
    <TimerProvider>
        <Router>
          <DataRoute>
            <Header/>
          </DataRoute>
          <Routes>
              <Route path='/' element={
                    <DataRoute>
                      <Main language={language()} onInstallClick={handleInstallClick} theme={theme}/>
                    </DataRoute>
              } />
              <Route path='/login' element={
                    <LoginRoute>
                      <Login/>
                    </LoginRoute>
              } />
              <Route path='/registeration' element={
                    <LoginRoute>
                      <Registeration/>
                    </LoginRoute>
              } />
              <Route path='/profile' element={
                    <ProtectedRoute>
                      <Profile theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/workout/my' element={
                    <ProtectedRoute>
                        <PersonalWorkout theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/workout/my/:id' element={
                    <ProtectedRoute>
                        <MyExercise />
                    </ProtectedRoute>
              } />
              <Route path='/workout/my/part/:id' element={
                    <ProtectedRoute>
                        <WorkoutPartList theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/workout/public' element={
                    <DataRoute>
                        <PublicWorkout theme={theme}/>
                    </DataRoute>
              } />
              <Route path='/workout/public/:id' element={
                    <DataRoute>
                        <PreviewScreen theme={theme} />
                    </DataRoute>
              } />
              <Route path='/workout/rate/:id' element={
                    <ProtectedRoute>
                        <RateWorkout theme={theme} />
                    </ProtectedRoute>
              } />
              <Route path='/workout/new' element={
                    <ProtectedRoute>
                        <NewWorkout theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/workout/edit/:id' element={
                    <ProtectedRoute>
                        <EditWorkout theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/calendar' element={
                    <ProtectedRoute>
                        <Calendar theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/analytics' element={
                    <ProtectedRoute>
                        <Analytics theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/notifications' element={
                    <ProtectedRoute>
                        <Notifications theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/workout/planer' element={
                    <ProtectedRoute>
                        <WorkoutPlaner theme={theme}/>
                    </ProtectedRoute>
              } />
              <Route path='/checkout' element={
                      <Checkout />    
              } />
              <Route path="/return" element={
                      <Return />
              } />
              <Route path='/verify' element={
                      <EmailVerification theme={theme}/>
              } />
              <Route path='/forgot/password' element={
                        <ForgotPassword theme={theme}/>
              } />
              <Route path='/reset/password' element={
                        <ResetPassword theme={theme}/>
              } />
              <Route path='/privacypolicy' element={
                        <PrivacyPolicy theme={theme}/>
              } />
              <Route path='/termofservice' element={
                        <TermOfService language={language()} theme={theme}/>
              } />
              <Route path='/contact' element={
                        <Contact theme={theme}/>
              } />
          </Routes> 
          <Nav/>
        </Router>
    </TimerProvider>
    </AuthProvider>
    </ThemeProvider>
  );
}

// Kuuntele tapahtumaa, joka kertoo yhteyden palautumisesta palvelimelle
window.addEventListener('online', savePerformanceDataToDatabase);

export default App;


