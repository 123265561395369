import React, {useState, useEffect} from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth, useData, useWebSocket } from "../../../Utils/UseContext";
import { GetPublicData, ConfirmAccessRight } from "../../../Utils/ApiRequests";
import { ExpiryDate, ScrollView, CustomPopUp } from "../../../components/Components";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled";
import { DescriptionContainer, Container, PublishDetailsContainer, ButtonContainer, Container100, ScrollAuto } from "../../../components/style/Container.styled";
import { Row, Column } from "../../../components/style/Row.styled";
import { WorkoutMedia } from "../StyledComponents";
import { DateNow, IncreaseDays } from "../../../Utils/Utils";
import { ShowStars } from "../../../components/Components";
import { ConfirmPayment } from "../../Stripe/StripeAPI";


const PreviewScreen = ({theme, isAuthenticated}) => {
    const {state} = useAuth()
    const navigate = useNavigate();
    const location = useLocation(); 
    const { id } = useParams();
    const {publicData, setPublicData} = useData(null);
    const [selectedItem, setSelectedItem] = useState(null); // Tila valitulle itemille
    const [isVisible, setIsVisible] = useState(false);
    const [popupContent, setPopupContent] = useState("");
    const ws = useWebSocket();

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const canceled = queryParams.get('canceled');

      if(canceled){
        toast.error("OOPS! Event canceled.");
        navigate(location.pathname, { replace: true });
      }

    },[location.search])


    useEffect(() => {
      if(ws){
        GetPublicData({setPublicData, ws});     
      }
    },[ws])

    useEffect(()=> {
        if (publicData.data && id) {
            const foundItem = publicData.data.find(item => item.workout_id === id); // Suodata data listasta id:n perusteella
            setSelectedItem(foundItem); // Aseta löydetty item tilaansa
          }
    },[publicData?.data, id])




    // const message = `Haluatko ostaa harjoitusohjelman: ${title}? \nHinta: ${price} \nAlkaen:${DateToString(DateNow())}  \nPäättyen:${DateToString(ExpiryDate(Number(expiredIn)))}`
  
    const handleCancel = () => {
      setIsVisible(false)
      // closeModal()
    }
  
    const handleConfirm = async () => {
      // tämän pitäisi suorittaa maksu operaatio ja kun operaatio suoritettu annetaan oikeus katsella harjoitusta.
      await ConfirmAccessRight(id, ExpiryDate(Number(selectedItem?.expired_time)))
      handleCancel()
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

      console.log("PRICE ID: ", selectedItem?.stripe_price_id)


        const priceId = selectedItem?.stripe_price_id

        const bodyData = {
          priceId: priceId, // Hinta-id, joka lähetetään palvelimelle
          workout_id: id,
          expiryDate: ExpiryDate(Number(selectedItem?.expired_time))
        };
      

        await ConfirmPayment(bodyData);
    };

    const returnCurrency = (currency) => {
      switch (currency) {
          case 'eur':
              return '€';
          case 'usd':
              return '$'
          default:
              return '€';
      }
    }

    const ConfirmationPopUp = () => {
     const buttons = [
       { label: 'No', onClick: handleCancel , bstyle: StyledSubmitButton},
       { label: 'Yes', onClick: handleConfirm, bstyle: StyledSubmitButton}
     ];

      const message =`Are you sure you want to buy ${selectedItem?.workout_name} for ${selectedItem?.price}${returnCurrency(selectedItem?.currency)}?` 
        setPopupContent({message: message, buttons: buttons});
        setIsVisible(!isVisible);
    }
    console.log("SELECTED ITEM: ", selectedItem)
    let starlist = [];
    if(selectedItem?.evaluations){
      starlist = selectedItem?.evaluations.map((evaluation) => evaluation?.stars);
    }

    return (
       <Container direction={'column'}>
        <Container100>
            <Row padding={'0px 0px 10px 0px'} style={{background: theme.colors.body}} borderBottom={`1px solid`}>
              <WorkoutMedia src={selectedItem?.media_url} type="video" minHeight={'80px'} minWidth={'80px'}/>
               <h3 style={{marginLeft: 10}}>{selectedItem?.workout_name}</h3>
            </Row>
            <h3>Description</h3>
            <Row align={'flex-start'} padding={'10px 0px 10px 0px'} style={{background: theme.colors.body}} height={'25%'} borderBottom={`1px solid`}>
              <ScrollAuto width={'100%'} height={'100%'}>
                {selectedItem?.description}
              </ScrollAuto>
            </Row>
            <Column width={'100%'} margin={'10px auto'} borderBottom={`1px solid`} padding={'0px 0px 10px 0px'}>
              <h5 style={{marginBottom: 5}}>The workout includes {selectedItem?.part_names?.length} parts</h5>
              {(selectedItem?.display_parts && selectedItem?.part_names?.length > 0)&& (
                <ScrollView items={selectedItem?.part_names} renderItem={(item) => <p style={{textTransform: 'uppercase'}}>{item}</p>}/>
                )
              }
            </Column>
           <PublishDetailsContainer>
            <Row padding={'0px 0px 10px 0px'} borderBottom={`1px solid`} align={'start'}>
              <Column width={'60%'} >
                <Row padding={'0px'} maxWidth={'150px'}>
                  <ShowStars stars={starlist}/>
                </Row>
                <p style={{fontSize: 10}}>The workout has received 5 reviews</p>
              </Column>
                <h5 onClick={() => console.log("POP UP TO COMMENTS")}>Comments: {selectedItem?.evaluations?.length}</h5>
            </Row>

             <Row padding={'0px 0px 10px 0px'} margin={'10px 0 0 0'} borderBottom={`1px solid`}>
                <Column>
                  <h5 style={{marginBottom: 5}}>Validity period</h5>
                  <p>{DateNow().toLocaleDateString()} - {IncreaseDays(selectedItem?.expired_time)}</p>
                  <p>The workout can be viewed for {selectedItem?.expired_time} days.</p>
                </Column>
                <Column justify={'flex-end'}>
                  <h5 style={{marginBottom: 5}}>Price</h5>
                  <span style={{marginRight: 10}}>
                    {selectedItem?.price === 0 ? 
                    <h3>FREE</h3> 
                    : 
                    <h3>{selectedItem?.price} {returnCurrency(selectedItem?.currency)}</h3>
                    }
                  </span>
                </Column>
             </Row>
           </PublishDetailsContainer>
           <ButtonContainer justify={'flex-end'} padding={'10px 0px 10px 0px'}>
            {state?.isAuthenticated && (
              <>
                <StyledSubmitButton onClick={() => navigate('/workout/public')} width={'100px'} style={{marginRight: 20}}>
                   Cancel
                 </StyledSubmitButton>
                 {selectedItem?.price === 0 ? 
                 <StyledSubmitButton onClick={() => ConfirmationPopUp()} width={'100px'}>
                   Buy {selectedItem?.price}{returnCurrency(selectedItem?.currency)}
                 </StyledSubmitButton>
                 :
                 <form onClick={handleSubmit}>
                    <StyledSubmitButton type="submit" width={'100px'}>Buy {selectedItem?.price}{returnCurrency(selectedItem?.currency)}</StyledSubmitButton>
                </form>
                }
              </>
            )}
           </ButtonContainer>
           <CustomPopUp content={popupContent} isOpen ={isVisible} setIsOpen={() => setIsVisible(!isVisible)} div={DescriptionContainer} />
           </Container100>
       </Container>
    )
  }

  export default PreviewScreen;