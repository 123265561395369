import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../Utils/UseContext.jsx';
import { CustomButton } from '../components/Buttons.jsx';
import { HandleRegisteration } from '../Utils/ApiRequests.jsx';

//components
import { CustomCheckBox } from '../components/Components.jsx'
import { checkPwdIsSame, checkPwdLen, isPwdStrong } from './AuthUtils.jsx';

//icon
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

//style
import { AuthContainer, AuthContent, FormContainer } from '../components/style/Container.styled.jsx';
import { StyledCustomInput } from '../components/style/CustomInput.styled.jsx';
import {Row} from '../components/style/Row.styled.jsx'
import '../components/style/Styles.css'
import { StyledSubmitButton } from '../components/style/Buttons.styled.jsx';

const ErrorMsg = ({letErr, lenErr, isSameErr, passwordfield, isValidEmail, isEmailExist, undefinedErr}) => {
    const letErrText = "Salasanan pitää sisältää ainakin yksi numero ja erikoismerkki."; // käännökset näille ja muille
    const lenErrText = "Salasanan oltava vähintään 10 merkkiä pitkä.";
    const isSameErrText = "Salasanat eivät täsmää.";
    const isValidEmailText = "Sähköposti virheellinen.";
    const isEmailExistText = "Käyttäjä on olemassa.";
    const undefinedErrText = "Something went wrong, check all your fields.";
  //"Salasanan tai tunnus väärin.";
  return(
      <div className='errorContainer'>
        <div>
            {!undefinedErr && (
                <>
                    {!isEmailExist && (
                        <>
                            {letErr? "" : <p>{letErrText}</p>}
                            {lenErr? "" : <p>{lenErrText}</p>}
                            {isSameErr && (<p>{isSameErrText}</p>)}
                            {!isValidEmail && (<p>{isValidEmailText}</p>)}
                        </>
                    )}
                        {isEmailExist && (<p>{isEmailExistText}</p>)}
                </>
            )}
            {undefinedErr && (<p>{undefinedErrText}</p>)}
        </div>
      </div>
  )
}


const Registeration = () => {
    const navigate = useNavigate();
    // const {setToken} = AuthContext();
    const { dispatch } = useAuth();

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isSame, setIsSame] = useState();
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isEmailExist, setEmailExist] = useState(false)
    const [undefinedErr, setUndefinedErr] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [privacyChecked, setprivacyChecked] = useState(false)

    const handlePassword = (target) => {
        setPassword(target);
    }

    const handlePassword2 = (target) => {
        setPassword2(target)
        setIsSame(!checkPwdIsSame(password, target))
    }

    const validateEmail = (text) => {
        const emailRegex = /\S+@\S+\.\S+/;
        const isValid = emailRegex.test(text);
        setIsValidEmail(isValid);
        setEmail(text);
    };

    const checkValidation = () => {
        if( checkPwdLen(password) && checkPwdIsSame(password, password2) && isPwdStrong(password) && isValidEmail){
            return true;
        }else{
            return false;
        }
    }

    return(
        <AuthContainer>
            <AuthContent>
            <FormContainer>
            <h3>Registration</h3>
                <StyledCustomInput
                  placeholder="Firstname"
                  value={firstname}
                  onChange={e => setFirstName(e.target.value)}
                  onFocus={() => setUndefinedErr(false)}
                />
                <StyledCustomInput
                  placeholder="Lastname"
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                  onFocus={() => setUndefinedErr(false)}
                />
                <StyledCustomInput
                  placeholder="Email"
                  value={email}
                  onChange={e => validateEmail(e.target.value)}
                  onFocus={() => (setUndefinedErr(false))}
                />
                <div className='password-container'>
                    <StyledCustomInput
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={e => handlePassword(e.target.value)}
                      type={showPassword ? 'text' : 'password'}
                      onFocus={() => setUndefinedErr(false)}
                    />
                    <span className='show-password' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 
                            <IoMdEyeOff size={25}/>
                            :
                            <IoMdEye size={25}/>
                        }
                    </span>
                </div>
                <StyledCustomInput
                  placeholder="Password again"
                  value={password2}
                  onChange={e => handlePassword2(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  onFocus={() => setUndefinedErr(false)}
                />
            <Row>
                <CustomCheckBox
                  isChecked={privacyChecked}
                  onChange={isChecked => setprivacyChecked(isChecked)}
                />
                <p>I have read and agree <span onClick={() => navigate('/privacypolicy')}> the privacy policy</span></p>
            </Row>
            <CustomButton disabled={!privacyChecked} style={{marginTop: '20px'}} button={StyledSubmitButton} label={"Register"} onClick={() => HandleRegisteration(firstname, lastname, email, password, dispatch, navigate, setEmailExist, checkValidation, setUndefinedErr)}/>

            </FormContainer>
            <ErrorMsg isEmailExist={isEmailExist} isValidEmail={isValidEmail} passwordfield={password} letErr={isPwdStrong(password)} lenErr={checkPwdLen(password)} isSameErr={isSame} undefinedErr={undefinedErr}/>
            <p>You have an account? <span onClick={() => navigate('/login')}>Login</span></p>
            </AuthContent>
      </AuthContainer>
    )
}

export default Registeration;