import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin: 0;
        // color: ${({theme}) => theme.colors.text || '#000'};
        cursor: default;
    }

    body{
        position: relative;
        height: 100vh;
        padding-top: 2em;
        // padding-bottom: 100em;
        background: ${({theme}) => theme.colors.body || '#fff'};
        color: ${({theme}) => theme.colors.text};
    }

    p{
        color: ${({theme}) => theme.colors.text || '#000'};
        // line-heigth: 1.5;
        // font-weight: 600;
    }

    select{
        color: ${({theme}) => theme.colors.text || '#000'};
    
    }

    h3{
        color: ${({theme}) => theme.colors.text || '#000'};
    }

    img{
        max-width: 100%;
    }

    // Desktop
    @media screen and (min-width: 481px) {
        body{

        }
    }

`

export default GlobalStyle; 