import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';
// API
import { VerifyEmail } from '../Utils/ApiRequests';

function EmailVerification() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verificationData, setVerificationData] = useState({email: null, code: null});
  const [isVerified, setIsVerified] = useState();
  const [message, setMessage] = useState("");
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    const code = searchParams.get('code');
    const email = searchParams.get('email');
    if (code && email) {
      // Aseta varmistuskoodi tilaan
      const verificationObj = {email: email, code: code}
      setVerificationData(verificationObj);
      // Voit tässä vaiheessa lähettää varmistuskoodin palvelimelle ja käsitellä varmistuksen
    }
  }, [searchParams]);

  useEffect(() => {
    let intervalId;
    if (counter > 0) {
      intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    }else if(counter === 0){
        navigate('/')
    }

    // Poista ajastin, kun komponentti puretaan (unmounted)
     return () => {
      clearInterval(intervalId);
     };
  }, [counter]);

  const handleVerification = async () => {
    const verificationResponse = await VerifyEmail(verificationData.email, verificationData.code);

    //API kutsu tietokannasta väliaikainen verifiointi koodi ja email pari. 
    // jos täsmää jatka, muuten hylkää ja pyydä lähettämään uusi.
    if(!verificationResponse?.verified){
        setIsVerified(false)

    }else{
        setIsVerified(true);
        setCounter(10)
    }
    setMessage(verificationResponse?.message)
  };


  return (
    <div>
      <h2>Varmista sähköpostisi</h2>
      <p>Varmistaaksesi sähköpostisi, klikkaa alla olevaa painiketta:</p>
      <button onClick={handleVerification}>Vahvista sähköposti</button>
      <p>{message}</p>
      {isVerified && ( 
            <p>Siirrytään automaattisesti <span style={{cursor:'pointer'}} onClick={() => navigate('/')}>etusivulle</span> {counter} sekunnin kuluttua.</p>
      )}
      {isVerified === false && (
        <p style={{cursor:'pointer'}} onClick={() => console.log("tilataan linkki")}>Reorder the email verification link again.</p>
      )}
    </div>
  );
}

export default EmailVerification;
