import { AddTokenToHeaders } from "../../Utils/Utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const host_name = process.env.REACT_APP_HOST_API_KEY;


export const ConfirmPayment = async (bodyData) => {
    const headers = await AddTokenToHeaders();

    try {
      const response = await fetch(`${host_name}/create-checkout-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(bodyData), // Muunna objekti JSON-muotoon
      });
      const body = await response.json();
    
      if(response.ok) {
        window.location.href = body.url
      }else{
        if(response.status === 409 || response.status === 403){
          toast.info(body?.message)
        }else{
           console.error("Error in response.", response.status)
        }
      }

    } catch (error) {
      console.error("error in payment: ",error)
    }
}

export const CreateStripeAccount = async (country) => {
  const headers = await AddTokenToHeaders();
  
  try {
    const response = await fetch(`${host_name}/create-stripe-account`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({country}), // Muunna objekti JSON-muotoon
    });
     const resp = await response.json();

    if(response.ok) {
      if(!resp?.createAccountUrl?.url){
        toast.warn(resp?.message);
        return;
      }
      
      window.location.href = resp?.createAccountUrl?.url
      return {loading: false}

      // return resp?.createAccountUrl?.url
    }else{

      console.error("Error in response.", response.status)
    }

  } catch (error) {
    console.error("error in creating account: ",error)
  }
}

export const StripeAccountLink = async () => {
  const headers = await AddTokenToHeaders();
  
  try {
    const response = await fetch(`${host_name}/create-stripe-account-link`, {
      method: 'POST',
      headers: headers
    });
     const resp = await response.json();

    if(response.ok) {
      console.log(resp)
      if(!resp?.link?.url){
        toast.warn(resp?.message);
        return;
      }
      
      window.location.href = resp?.link?.url
      return {loading: false}

      // return resp?.createAccountUrl?.url
    }else{

      console.error("Error in response.", response.status)
    }

  } catch (error) {
    console.error("error in creating account: ",error)
  }
}

export const CheckStripeConnection = async () => {
  const headers = await AddTokenToHeaders();
  
  try {
    const response = await fetch(`${host_name}/check-stripe-connection`, {
      method: 'GET',
      headers: headers
    });

     const resp = await response.json();

    if(response.ok) {
      return resp?.status;

    }else{
      toast.error(resp?.error)
    }

  } catch (error) {
    console.error("error in creating account: ",error)
  }
}