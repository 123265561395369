import React, {useEffect, useState, useRef} from "react";
import { useNavigate } from 'react-router-dom';

//API
import { Notify } from "../Utils/ApiRequests.jsx";

// components
import PublicWorkoutSection from "./Workout/Public/PublicWorkoutSection.jsx";
import WorkoutDetails from "./Workout/WorkoutDetails.jsx";
import Footer from "../components/Footer.jsx";
import HeaderText from "./HeaderText.jsx";
import MultiTextSection from "../components/MultiTextSection.jsx";
import CookieBanner from "../components/CookieBanner.jsx";
//Style
import { HomeContentContainer, MainContentContainer, TextContentContainer, TextContainer, ColumnContainer, FadeInContainer } from "../components/style/Container.styled.jsx";
import { Row, Column } from "../components/style/Row.styled.jsx";
import { MainContainer, MainSection, TopSquare, TopTriangle } from "../components/style/Section.styled.jsx";
import { MainDescriptionP } from "../components/style/paragraph.styled.jsx";
//image
import home_workout from '../components/style/images/home_workout.jpg'
import gym_workout from '../components/style/images/gym_workout.jpg'
import gym from '../components/style/images/gym.jpg'
import gym1 from '../components/style/images/gym1.jpg'
//utils
import { GetCookieData } from "../Utils/Utils.jsx";
import { GetPublicData } from "../Utils/ApiRequests.jsx";
import { useData, useWebSocket } from "../Utils/UseContext.jsx";
//ADS
import AdSense from "../Ads/Adsense.jsx";



export const ContentContainer = ({title, content, width, justify, align, bg, marginTop, titleColor, nav, Style}) => {

    return(
        <MainContentContainer bg={bg} width={width} justify={justify} align={align} marginTop={marginTop}>
            <Column>
                <Row padding={'none'} color={titleColor} onClick={nav}>
                    <h3 style={Style}>{title}</h3>
                </Row>
                {content}
            </Column>
        </MainContentContainer>
    )
}

function FadeInSection1(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    return (
      <FadeInContainer
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </FadeInContainer>
    );
  }

const MainContent = ({isAuthenticated, theme, data}) => {
    const navigate = useNavigate();
    let content; 

    // <ContentContainer title={'News'} content={newsContent('We will be releasing new features soon!')} bg={'transparent'} align={'flex-end'}/>,
    // <ContentContainer title={'Public workout'} content={<PublicWorkoutSection />} nav={() => navigate('/workout/public')} bg={'transparent'} Style={{cursor:'pointer'}}/>
    

    const contentList = [
        <Row>
            <MainDescriptionP theme={theme}>Whether you're a seasoned athlete or just starting your fitness journey, our app empowers you to create, share, and discover workout routines tailored to your goals.</MainDescriptionP>
        </Row>,
        <Row justify={'end'}>
            <MainDescriptionP theme={theme}><span className="gradient-text">Create Custom Workouts</span>: Design your own training programs with ease. Tailor exercises, sets, and reps to match your personal fitness objectives, and adjust them as you progress.</MainDescriptionP>
        </Row>,
        <Row>
            <MainDescriptionP theme={theme}><span className="underline-text gradient-text" onClick={() => navigate('/workout/public')} style={{cursor:'pointer', textDecoration: 'underline'}}>Share & Discover</span>: Share your routines with the community or explore workouts created by other users. From strength training to cardio, find programs that inspire you and push your limits.</MainDescriptionP>
        </Row>,
        <Row justify={'end'}>
            <MainDescriptionP theme={theme}>
                <span className="gradient-text">Track Your Progress</span>: Track your progress over time, analyze your strengths and weaknesses, and refine your approach to reach new heights.
            </MainDescriptionP>
        </Row>,
        <Row>
            <MainDescriptionP theme={theme}>
                <span className="gradient-text">Plan Your Fitness Journey</span>: Use our calendar to schedule your workouts. Plan your week, month, or even year in advance, ensuring you stay on track and consistent.
            </MainDescriptionP>
        </Row>,  
        <Row justify={'end'}>
            <MainDescriptionP theme={theme}>
                Start today and take control of your fitness journey. Whether you aim to build muscle, lose weight, or just stay active, our app is your partner every step of the way. <span onClick={() => navigate('/registeration')} style={{cursor:'pointer', textDecoration: 'underline', textTransform: 'uppercase'}}>Join us</span> and start achieving your fitness goals like never before!
            </MainDescriptionP>
        </Row>  
        
    ]

    {!isAuthenticated &&
         (content = 
            <MainContentContainer justify={'space-between'} >
                    <MultiTextSection texts={contentList} />
            </MainContentContainer>
        )
    }
    return content;
}

const Main = ({onInstallClick, language, theme}) => {
    const navigate = useNavigate();

    const sectionRefs = useRef([]);
    const {publicData, setPublicData, setNotifications} = useData();

    const isAuthenticated = GetCookieData()?.isAuthenticated;

    const ws = useWebSocket();

    useEffect(() => {
      if (ws) {
        GetPublicData({setPublicData, ws});
      }
    }, [ws]);
    
    // Content to this should come frome DB, now it is hard coded
    const newsContent = (news) => {
        return(
            <HomeContentContainer>
                <TextContentContainer>
                    {news}
                </TextContentContainer>
            </HomeContentContainer>
        )
    }

    return(
        <>
            <CookieBanner />
            <MainSection >
                <Row margin={'30px auto'} justify_mobile={'center'} margin_mobile={'30px auto'}>
                    <HeaderText theme={theme}/>
                </Row>
                <TopSquare image={gym}/>
                <MainContent isAuthenticated ={isAuthenticated} theme={theme} data={publicData}/>
            </MainSection>

                {isAuthenticated && (
                    <MainContainer>
                        <Row margin={'10px auto'}>
                            <ContentContainer title={'News'} content={newsContent('We will be releasing new features soon!')} bg={'transparent'}/>
                        </Row>
                        <Row margin={'20px auto'} justify={'end'}>
                            <WorkoutDetails/>
                        </Row>
                        <Row margin={'20px auto'}>
                            <ContentContainer title={'Public workout'} content={<PublicWorkoutSection theme={theme}/>} nav={() => navigate('/workout/public')} bg={'transparent'} Style={{cursor:'pointer'}}/>
                        </Row>
                    </MainContainer>
                )}
            <Footer language={language} onInstallClick={onInstallClick}/>
        </>
    )
}

export default Main