import React, {useState, useEffect} from "react";
import {jwtDecode} from 'jwt-decode';
import { InsertCompletedIntoDb } from "./ApiRequests";
import ClipLoader from "react-spinners/ClipLoader";


export const FilterEventsByDateRange = (events, {startOfWeek, endOfWeek}) => {

  // const { startOfWeek, endOfWeek } = weekrange;
  //GetWeekRange();
  return events.filter(event => {
      const eventDate = new Date(event.date);
      return eventDate >= startOfWeek && eventDate <= endOfWeek;
  });
};

export const DateNow = () => {
  const dateNow = new Date();
  return dateNow;
}

export const GetWeekRange = () => {
  const today = new Date();
  let dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)

  // Asetetaan sunnuntai 7:ksi ja muut päivät vastaavasti
  dayOfWeek = (dayOfWeek === 0) ? 7 : dayOfWeek;

  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - (dayOfWeek - 1)); // Set to start of the week (Sunday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to end of the week (Saturday)

  return { startOfWeek, endOfWeek };
};

export const GetPreviousWeekRange = () => {
  
  // Maanantai edellisellä viikolla
  const startOfWeek = new Date(GetWeekRange().startOfWeek);
  startOfWeek.setDate(startOfWeek.getDate() - 7);

  // Sunnuntai edellisellä viikolla
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return { startOfWeek, endOfWeek };
};

export const DateToString = (now, increase) => {
  const isOne = increase ? 1 : 0;

  // const now = new Date();
  const date = now.getUTCDate();
  const month = now.getUTCMonth();
  const year = now.getUTCFullYear();
  return `${date + isOne}.${month + 1}.${year}`;
}

export const TimeToString = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

export const MillisToSec = (value) => {
  const sec = value / 1000;
  return sec;
}

export const MillisToMinutes = (value) => {
  const min = (MillisToSec(value) / 60);
  return min;
}

export const GetCookieData = () => {
  const cookies = document.cookie.split(';');
  const cookieData = cookies.find(cookie => cookie.trim().startsWith('user='));
  if (cookieData) {
      const userData = cookieData.split('=')[1];
      return JSON.parse(decodeURIComponent(userData));
  }
  return null;
};

export const ClearLoginCookie = () => {
  // Aseta evästeen arvo tyhjäksi
  document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export const GetdecodeToken = async () => {
  const token = await GetCookieData()?.token;
  const decodedToken = jwtDecode(token)
  return decodedToken;
}

export const AddTokenToHeaders = async (headers = {}) => {
  // Hae tallennettu token
  const cookie = GetCookieData();
  const storedToken = cookie ? cookie.token : null;

  if (storedToken) {
   //  headers['Authorization'] = `Bearer ${storedToken.password}`;
    headers['Authorization'] = `${storedToken}`;
    headers['Content-type'] = 'application/json';
  }

  return headers;
};

export const AuthorizationHeaders = async (headers = {}) => {
  // Hae tallennettu token
  const cookie = GetCookieData();
  const storedToken = cookie ? cookie.token : null;

  if (storedToken) {
   //  headers['Authorization'] = `Bearer ${storedToken.password}`;
    headers['Authorization'] = `${storedToken}`;
   
  }

  return headers;
};

export const Loading = ({data, timeout, children, theme}) => {
  const [timeoutExceeded, setTimeoutExceeded] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutExceeded(false);
    }, timeout);

    // Siivotaan ajastin komponentin unmountin yhteydessä
    return () => clearTimeout(timer);
  }, [data, timeout]);

  const loaded = data;
    return (!loaded ? ( 
      <div style={{marginTop: 50, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <ClipLoader
          color={theme.colors.button}
          loading={loaded}
          // cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <p>Loading...</p>
      </div>
  ) : children
  )
}

export const TextMinLenCount = (text, minLen) => {
  const isMinLen = text.length >= minLen;
  return isMinLen;
}

export const Average = (array) => {
  // const len = array.length;
  const average = array.reduce((acc,v,i,a)=>(acc+v/a.length),0);

  return average;
}

export const SetToLocalStorage = (key, value) => {
  // localStorage.setItem(key, JSON.stringify(value));

  try {
    if (typeof key !== 'string' || key === '') {
      throw new Error('Invalid key');
    }
    
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);

  } catch (error) {
    if (error) {
      console.error("localStorage is full: ", error);
    } else if (error.name === 'TypeError') {
      console.error("JSON.stringify failed", error);
    } else {
      console.error("Failed to set localStorage item", error);
    }
  }
}

export const getFromLocalstorage = (key) => {
  const data = JSON.parse(localStorage.getItem(key))
  return data;
}

export const getLast = async (where, what) => {
  // Haetaan kaikki localStorage avaimet
  const keys = where;
  // Suodatetaan avaimet, jotka alkavat "performance-"
  const keyList = keys.filter(key => key.startsWith(what)); 

  keyList.sort((a, b) => {
    const numA = parseInt(a.replace(what, ""), 10);
    const numB = parseInt(b.replace(what, ""), 10);
    return numA - numB;
  });

  const index = keyList[keyList.length - 1]?.substring("performance-".length)

  return index ? index : 0;
}

export const findNextEvent = (events) => {
  const now = new Date();
  
  // Filter events to include only those that are in the future
  const futureEvents = events.filter(event => new Date(event.date_time) > now);

  if (futureEvents.length === 0) {
    return null; // No future events
  }

  // Find the event with the closest date
  let nextEvent = futureEvents[0];
  let minTimeDifference = new Date(nextEvent.date) - now;

  futureEvents.forEach(event => {
    const eventDate = new Date(event.date);
    const timeDifference = eventDate - now;

    if (timeDifference < minTimeDifference) {
      nextEvent = event;
      minTimeDifference = timeDifference;
    }
  });

  return nextEvent;
};

export const savePerformanceDataToDatabase = async () => {
  const performanceKeys = [];

  // Etsi kaikki localStorage:n avaimet
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i); // Avain

    // Tarkista, että avain alkaa merkkijonolla "performance-"
    if (key.startsWith('performance-')) {
      performanceKeys.push(key); // Lisää avain listaan
    }
  }

  // Käy läpi kaikki performanceKeys-taulukon avaimet ja tallenna ne tietokantaan
    for(let i = 0; i < performanceKeys.length; i++){
      const key = performanceKeys[i]
      const value = localStorage.getItem(key); // Haetaan arvo localStoragesta

      await InsertCompletedIntoDb(key, value);
    }
};

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length <= 0
}

export const language = () => {
  return navigator.language
};

export const isAccessRightValid = (accessRight, value) => {
  const isValid = accessRight === value;
  return isValid;
}

// Funktio kuvan optimointiin
export const OptimizeImage = (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
              const MAX_WIDTH = 120;
              const MAX_HEIGHT = 120;
              let width = img.width;
              let height = img.height;

              // Laske kuvasuhde
              if (width > height) {
                  if (width > MAX_WIDTH) {
                      height *= MAX_WIDTH / width;
                      width = MAX_WIDTH;
                  }
              } else {
                  if (height > MAX_HEIGHT) {
                      width *= MAX_HEIGHT / height;
                      height = MAX_HEIGHT;
                  }
              }

              // Luo canvas optimointia varten
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');

              ctx.drawImage(img, 0, 0, width, height);

              // Pakkaa kuva ja konvertoi Blob-muotoon
              canvas.toBlob((blob) => {
                  resolve(new File([blob], file.name, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  }));
              }, 'image/jpeg', 1); // 90% laatu
          };

          img.onerror = (err) => reject(err);
      };

      reader.onerror = (err) => reject(err);
  });
};

export const CapitalizeFirstLetter = (string) => {
  if (!string) return ''; // Varmistetaan, ettei käsitellä tyhjää merkkijonoa
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const IncreaseDays = (expVal) => {
  const now = new Date();
  const expDate = new Date(now);
  expDate.setDate(now.getDate() + parseInt(expVal));
  return expDate.toLocaleDateString();
}



